import React from 'react';
import AboutBody from './aboutBody';

const AboutView = () => (
  <>
    <AboutBody />
  </>
);

export default AboutView;
